@font-face {
  font-family: 'Fixedsys';
  src: url('./Fixedsys.ttf');
}

body {
  margin: 0;
  font-family: 'Fixedsys', 'Courier New', Courier, monospace;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  display: flex;
  align-items: center;
  justify-content: center;

  background: linear-gradient(180deg, rgb(255, 255, 255) 0%, rgb(247, 247, 234) 44%, rgb(255, 251, 202) 100%);
}

html,
body {
  height: 100%;
  width: 100%;
}

* {
  user-select: none;
  image-rendering: pixelated;
}

#root {
  height: 100%;
}

:root {
  --beach: #e1e096;
  --sky: #13bdf2;
  --sea: #0044cc;
}

#root {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 800px) {
  #root {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }
}
